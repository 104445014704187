import { render, staticRenderFns } from "./RatingAndDate.vue?vue&type=template&id=2f06d353&scoped=true&"
import script from "./RatingAndDate.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RatingAndDate.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RatingAndDate.vue?vue&type=style&index=0&id=2f06d353&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f06d353",
  null
  
)

export default component.exports
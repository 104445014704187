import { render, staticRenderFns } from "./DateFormated.vue?vue&type=template&id=c30b8570&scoped=true&"
import script from "./DateFormated.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DateFormated.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./DateFormated.vue?vue&type=style&index=0&id=c30b8570&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c30b8570",
  null
  
)

export default component.exports